import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
          Coming Soon
        </p>
        <a
            className="App-link"
            href="https://snushuttles.com"
            target="_blank"
            rel="noopener noreferrer"
        >
          Book Shuttles to Delhi
        </a>
        <br/>
        <a
            className="App-link"
            href="https://forms.gle/Ki8r8ZWGw4JpCNUJ7"
            target="_blank"
            rel="noopener noreferrer"
        >
          Join the Waitlist for Mars Trip
        </a>
      </header>
    </div>
  );
}

export default App;
